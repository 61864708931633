import { createPageRoute } from "./PageRouteDefinition";
import { createRouteSegment } from "./RouteSegment";
import { routeParam, routeTemplate, spaceIdPlaceholder } from "./RouteTemplate";
import { createRedirect } from "./createRedirect";
import { optionalBooleanQueryParam, createQueryParam, stringArrayQueryParam, optionalStringQueryParam, dateQueryParam, createArrayQueryParam, stringQueryParam, booleanQueryParam, numberQueryParam } from "./query/QueryStringParam";
import { spacePartitionQueryParams } from "./spacePartitionQueryParams";
export const insightsSplitParam = createQueryParam("split", validateQuerySplit, (v) => v);
export const insightsCadenceParam = createQueryParam("cadence", validateQueryCadence, (v) => v);
export const insightsTenantTagSetIdParam = optionalStringQueryParam("tenantTagSetId");
export const taskFilterStateParam = createQueryParam("state", validateTaskFilterState, (v) => v);
export const taskListTabParam = createQueryParam("taskListTab", validateTaskListTab, (v) => v);
export const taskDateFilterTypeParam = createQueryParam("dateFilterType", validateTaskDateFilterType, (v) => v);
export const variableSetTabParam = createQueryParam("activeTab", validateVariableSetTab, (v) => v);
export const projectInsightsChannelIdParam = optionalStringQueryParam("channelId");
export const projectInsightsEnvironmentIdParam = optionalStringQueryParam("environmentId");
export const projectInsightsTenantIdParam = optionalStringQueryParam("tenantId");
export const machineHealthStatusesParam = createArrayQueryParam("healthStatuses", validateMachineHealthStatus, (v) => v ?? []);
export const communicationStylesParam = createArrayQueryParam("commStyles", validateCommunicationStyle, (v) => v ?? []);
const sharedMachineFilterParams = [optionalBooleanQueryParam("isDisabled"), machineHealthStatusesParam, optionalStringQueryParam("partialName")] as const;
// Every property name for every page/redirect must be unique across this object.
// This is because `links` flattens this tree down to a flat object, but preserves the property names of pages and redirects.
export const allRoutes = createRouteSegment(routeTemplate ``, {
    rootRedirect: createRedirect(routeTemplate `/`),
    system: createRouteSegment(routeTemplate `${spaceIdPlaceholder}`, {
        configuration: createRouteSegment(routeTemplate `/configuration`, {
            configurationRootRedirect: createRedirect(routeTemplate ``),
            auditPage: createPageRoute(routeTemplate `/audit`, [
                stringArrayQueryParam("environments"),
                stringArrayQueryParam("users"),
                stringArrayQueryParam("projects"),
                stringArrayQueryParam("projectGroups"),
                stringArrayQueryParam("eventCategories"),
                stringArrayQueryParam("eventGroups"),
                stringArrayQueryParam("eventAgents"),
                stringArrayQueryParam("documentTypes"),
                stringArrayQueryParam("tenants"),
                stringArrayQueryParam("tags"),
                optionalStringQueryParam("from"),
                optionalStringQueryParam("to"),
                stringArrayQueryParam("regardingAny"),
                optionalBooleanQueryParam("includeSystem"),
                stringArrayQueryParam("spaces"),
            ]),
            auditArchivePage: createPageRoute(routeTemplate `/audit-archive`, []),
            backupPage: createPageRoute(routeTemplate `/backup`, []),
            diagnosticsPage: createPageRoute(routeTemplate `/diagnostics`, []),
            diagnosticLogsPage: createPageRoute(routeTemplate `/diagnostics/logs`, []),
            diagnosticAutoDeployLogsPage: createPageRoute(routeTemplate `/diagnostics/autodeploy`, []),
            diagnosticScheduledDeployLogsPage: createPageRoute(routeTemplate `/diagnostics/scheduleddeploy`, []),
            diagnosticSubscriptionLogsPage: createPageRoute(routeTemplate `/diagnostics/subscription`, []),
            diagnosticMachineCleanupRedirect: createRedirect(routeTemplate `/diagnostics/machinecleanup`),
            featuresPage: createPageRoute(routeTemplate `/features`, []),
            letsEncryptPage: createPageRoute(routeTemplate `/letsencrypt`, []),
            configureLetsEncryptPage: createPageRoute(routeTemplate `/letsencrypt/configure`, []),
            maintenancePage: createPageRoute(routeTemplate `/maintenance`, []),
            performancePage: createPageRoute(routeTemplate `/performance`, []),
            serverSettingsPage: createPageRoute(routeTemplate `/nodes/serverSettings`, []),
            nodesPage: createPageRoute(routeTemplate `/nodes`, []),
            licensePage: createPageRoute(routeTemplate `/license`, []),
            licenseUsagePage: createPageRoute(routeTemplate `/licenseUsage`, []),
            smtpPage: createPageRoute(routeTemplate `/smtp`, []),
            gitPage: createPageRoute(routeTemplate `/git`, []),
            subscriptionsPage: createPageRoute(routeTemplate `/subscriptions`, []),
            editSubscriptionPage: createPageRoute(routeTemplate `/subscriptions/${routeParam("subscriptionId")}`, []),
            createSubscriptionPage: createPageRoute(routeTemplate `/subscriptions/create`, [
                stringArrayQueryParam("environments"),
                stringArrayQueryParam("users"),
                stringArrayQueryParam("projects"),
                stringArrayQueryParam("projectGroups"),
                stringArrayQueryParam("eventCategories"),
                stringArrayQueryParam("eventGroups"),
                stringArrayQueryParam("eventAgents"),
                stringArrayQueryParam("documentTypes"),
                stringArrayQueryParam("tenants"),
                stringArrayQueryParam("tags"),
                optionalStringQueryParam("from"),
                optionalStringQueryParam("to"),
                stringArrayQueryParam("regardingAny"),
                optionalBooleanQueryParam("includeSystem"),
                stringArrayQueryParam("spaces"),
            ]),
            testPermissionsPage: createPageRoute(routeTemplate `/testpermissions`, []),
            testPermissionsForUserPage: createPageRoute(routeTemplate `/testpermissions/${routeParam("userId")}`, []),
            thumbprintPage: createPageRoute(routeTemplate `/thumbprint`, []),
            userRolesPage: createPageRoute(routeTemplate `/roles`, []),
            createUserRolePage: createPageRoute(routeTemplate `/roles/create`, []),
            editUserRolePage: createPageRoute(routeTemplate `/roles/${routeParam("roleId")}`, []),
            spacesPage: createPageRoute(routeTemplate `/spaces`, []),
            editSpacePage: createPageRoute(routeTemplate `/spaces/${routeParam("currentSpaceId")}`, []),
            teamsPage: createPageRoute(routeTemplate `/teams`, [...spacePartitionQueryParams, stringQueryParam("name")]),
            editTeamPage: createPageRoute(routeTemplate `/teams/${routeParam("teamId")}`, []),
            usersPage: createPageRoute(routeTemplate `/users`, []),
            createUserPage: createPageRoute(routeTemplate `/users/create`, []),
            editUserPage: createPageRoute(routeTemplate `/users/${routeParam("userId")}`, []),
            signingKeysPage: createPageRoute(routeTemplate `/signingkeys`, []),
            telemetryPage: createPageRoute(routeTemplate `/telemetry`, []),
            userInvitesPage: createPageRoute(routeTemplate `/userinvites`, []),
            scopedUserRoleToTeamRedirect: createRedirect(routeTemplate `/teams/redirect/${routeParam("scopedRoleId")}`),
            settings: createRouteSegment(routeTemplate `/settings`, {
                settingsListPage: createPageRoute(routeTemplate ``, []),
                activeDirectorySettingsPage: createPageRoute(routeTemplate `/authentication-directoryservices`, []),
                archiveLimitsSettingsPage: createPageRoute(routeTemplate `/archive-limit`, []),
                authenticationSettingsPage: createPageRoute(routeTemplate `/authentication`, []),
                azureADSettingsPage: createPageRoute(routeTemplate `/authentication-aad`, []),
                azureDevOpsIssueTrackerSettingsPage: createPageRoute(routeTemplate `/issuetracker-azuredevops-v2`, []),
                eventRetentionSettingsPage: createPageRoute(routeTemplate `/eventretention`, []),
                githubIssueTrackerSettingsPage: createPageRoute(routeTemplate `/issuetracker-github`, []),
                googleAppsSettingsPage: createPageRoute(routeTemplate `/authentication-googleapps`, []),
                guestLoginSettingsPage: createPageRoute(routeTemplate `/authentication-guest`, []),
                jiraIntegrationSettingsPage: createPageRoute(routeTemplate `/jira-integration`, []),
                jiraServiceManagementIntegrationSettingsPage: createPageRoute(routeTemplate `/jiraservicemanagement-integration`, []),
                ldapSettingsPage: createPageRoute(routeTemplate `/authentication-ldap`, []),
                octopusIdSettingsPage: createPageRoute(routeTemplate `/authentication-octopusid`, []),
                oktaSettingsPage: createPageRoute(routeTemplate `/authentication-od`, []),
                retentionPoliciesSettingsPage: createPageRoute(routeTemplate `/retentionpolicies`, []),
                serverFoldersSettingsPage: createPageRoute(routeTemplate `/server-folders`, []),
                serviceNowIntegrationSettingsPage: createPageRoute(routeTemplate `/servicenow-integration`, []),
                updatesSettingsPage: createPageRoute(routeTemplate `/upgrades`, []),
                usernamePasswordSettingsPage: createPageRoute(routeTemplate `/authentication-usernamepassword`, []),
                webPortalSettingsPage: createPageRoute(routeTemplate `/webportal`, []),
                webRequestProxySettingsPage: createPageRoute(routeTemplate `/server-proxy`, []),
            }),
            deploymentFreezesListPage: createPageRoute(routeTemplate `/deploymentfreezes`, []),
            deploymentFreezesCreatePage: createPageRoute(routeTemplate `/deploymentfreezes/create`, []),
            deploymentFreezesEditPage: createPageRoute(routeTemplate `/deploymentfreezes/edit/${routeParam("deploymentFreezeId")}`, []),
        }),
        currentUser: createRouteSegment(routeTemplate `/users/me`, {
            currentUserRootRedirect: createRedirect(routeTemplate ``),
            currentUserDetailsPage: createPageRoute(routeTemplate `/details`, []),
            currentUserLoginsPage: createPageRoute(routeTemplate `/logins`, []),
            currentUserApiKeysPage: createPageRoute(routeTemplate `/apiKeys`, []),
            currentUserActivityPage: createPageRoute(routeTemplate `/activity`, []),
        }),
        tasksPage: createPageRoute(routeTemplate `/tasks`, [
            stringArrayQueryParam("ids"),
            taskFilterStateParam,
            optionalStringQueryParam("project"),
            optionalStringQueryParam("runbook"),
            optionalStringQueryParam("environment"),
            optionalStringQueryParam("name"),
            optionalStringQueryParam("serverNode"),
            optionalStringQueryParam("tenant"),
            stringArrayQueryParam("spaces"),
            optionalBooleanQueryParam("includeSystem"),
            optionalBooleanQueryParam("hasPendingInterruptions"),
            optionalBooleanQueryParam("hasWarningsOrErrors"),
            taskDateFilterTypeParam,
            dateQueryParam("fromDate"),
            dateQueryParam("toDate"),
            taskListTabParam,
        ]),
        taskPage: createPageRoute(routeTemplate `/tasks/${routeParam("taskId")}`, []),
        taskRawLogPage: createPageRoute(routeTemplate `/tasks/${routeParam("taskId")}/raw`, []),
        scriptConsolePage: createPageRoute(routeTemplate `/tasks/console`, [optionalStringQueryParam("retry")]),
        interruptionToTaskRedirect: createRedirect(routeTemplate `/tasks/interruption/${routeParam("interruptionId")}`),
        styleGuidePage: createPageRoute(routeTemplate `/style-guide`, [optionalStringQueryParam("activeTab")]),
    }),
    space: createRouteSegment(routeTemplate `/${routeParam("spaceId")}`, {
        spaceRootRedirect: createRedirect(routeTemplate ``),
        dashboardPage: createPageRoute(routeTemplate `/dashboard`, []),
        dashboardConfigurationPage: createPageRoute(routeTemplate `/dashboard/configure`, []),
        editProjectGroupPage: createPageRoute(routeTemplate `/projectGroups/${routeParam("projectGroupId")}`, []),
        releaseRedirect: createRedirect(routeTemplate `/releases/${routeParam("releaseId")}`),
        triggerRedirect: createRedirect(routeTemplate `/triggers/${routeParam("triggerId")}`),
        channelRedirect: createRedirect(routeTemplate `/channels/${routeParam("channelId")}`),
        runbookSnapshotRedirect: createRedirect(routeTemplate `/snapshots/${routeParam("runbookSnapshotId")}`),
        runbookRedirect: createRedirect(routeTemplate `/runbooks/${routeParam("runbookId")}`),
        runbookRunRedirect: createRedirect(routeTemplate `/runs/${routeParam("runbookRunId")}`),
        infrastructure: createRouteSegment(routeTemplate `/infrastructure`, {
            infrastructureRootRedirect: createRedirect(routeTemplate ``),
            deploymentTargetsPage: createPageRoute(routeTemplate `/machines`, [
                ...sharedMachineFilterParams,
                stringArrayQueryParam("environmentIds"),
                stringArrayQueryParam("roles"),
                stringArrayQueryParam("tenantIds"),
                stringArrayQueryParam("tenantTags"),
                stringArrayQueryParam("shellNames"),
                stringArrayQueryParam("deploymentTargetTypes"),
                optionalBooleanQueryParam("hideEmptyEnvironments"),
                optionalStringQueryParam("machinePartialName"),
            ]),
            newDeploymentTargetPage: createPageRoute(routeTemplate `/machines/new`, []),
            newDeploymentTargetWithEnvironmentPage: createPageRoute(routeTemplate `/machines/new/${routeParam("environmentId")}`, []),
            createDeploymentTargetPage: createPageRoute(routeTemplate `/machines/create`, [
                optionalStringQueryParam("type"),
                optionalStringQueryParam("deploymentTargetTypeId"),
                optionalStringQueryParam("environment"),
                optionalStringQueryParam("uri"),
                optionalStringQueryParam("thumbprint"),
                optionalStringQueryParam("proxyId"),
                optionalStringQueryParam("host"),
                optionalStringQueryParam("port"),
                optionalStringQueryParam("hostKeyAlgorithm"),
                optionalStringQueryParam("fingerprint"),
            ]),
            deploymentTargetConnectionPage: createPageRoute(routeTemplate `/machines/${routeParam("machineId")}/connection`, []),
            deploymentTargetSettingsPage: createPageRoute(routeTemplate `/machines/${routeParam("machineId")}/settings`, []),
            deploymentTargetDeploymentsPage: createPageRoute(routeTemplate `/machines/${routeParam("machineId")}/deployments`, []),
            deploymentTargetRunbookRunsPage: createPageRoute(routeTemplate `/machines/${routeParam("machineId")}/runbookruns`, []),
            deploymentTargetEventsPage: createPageRoute(routeTemplate `/machines/${routeParam("machineId")}/events`, []),
            discoverMachinePage: createPageRoute(routeTemplate `/machines/discover/${routeParam("endpointKey")}`, [optionalStringQueryParam("category")]),
            discoverMachineWithEnvironmentPage: createPageRoute(routeTemplate `/machines/discover/${routeParam("endpointKey")}/${routeParam("environmentId")}`, [optionalStringQueryParam("category")]),
            createWorkerMachinePage: createPageRoute(routeTemplate `/workers/create`, [
                optionalStringQueryParam("type"),
                optionalStringQueryParam("workerPool"),
                optionalStringQueryParam("environment"),
                optionalStringQueryParam("uri"),
                optionalStringQueryParam("thumbprint"),
                optionalStringQueryParam("proxyId"),
                optionalStringQueryParam("host"),
                optionalStringQueryParam("port"),
                optionalStringQueryParam("hostKeyAlgorithm"),
                optionalStringQueryParam("fingerprint"),
            ]),
            workerMachinesPage: createPageRoute(routeTemplate `/workers`, [
                ...sharedMachineFilterParams,
                stringArrayQueryParam("workerPoolIds"),
                stringArrayQueryParam("shellNames"),
                optionalStringQueryParam("machinePartialName"),
                stringArrayQueryParam("roles"),
                optionalBooleanQueryParam("hideEmptyWorkerPools"),
                communicationStylesParam,
            ]),
            newWorkerMachinePage: createPageRoute(routeTemplate `/workers/new`, []),
            newWorkerMachineWithWorkerPoolPage: createPageRoute(routeTemplate `/workers/new/${routeParam("workerPoolId")}`, []),
            discoverWorkerMachinePage: createPageRoute(routeTemplate `/workers/discover/${routeParam("endpointKey")}`, [optionalStringQueryParam("category")]),
            discoverWorkerMachineWithWorkerPoolPage: createPageRoute(routeTemplate `/workers/discover/${routeParam("endpointKey")}/${routeParam("workerPoolId")}`, [optionalStringQueryParam("category")]),
            workerMachineConnectionPage: createPageRoute(routeTemplate `/workers/${routeParam("machineId")}/connection`, []),
            workerMachineSettingsPage: createPageRoute(routeTemplate `/workers/${routeParam("machineId")}/settings`, []),
            workerMachineEventsPage: createPageRoute(routeTemplate `/workers/${routeParam("machineId")}/events`, []),
            infrastructureEnvironmentPage: createPageRoute(routeTemplate `/environments/${routeParam("environmentId")}`, []),
            infrastructureEnvironmentsPage: createPageRoute(routeTemplate `/environments`, []),
            infrastructureOverviewPage: createPageRoute(routeTemplate `/overview`, []),
            workerPoolsPage: createPageRoute(routeTemplate `/workerpools`, [
                ...sharedMachineFilterParams,
                stringArrayQueryParam("workerPoolIds"),
                stringArrayQueryParam("shellNames"),
                optionalStringQueryParam("machinePartialName"),
                stringArrayQueryParam("roles"),
                optionalBooleanQueryParam("hideEmptyWorkerPools"),
                communicationStylesParam,
            ]),
            editWorkerPoolPage: createPageRoute(routeTemplate `/workerpools/${routeParam("workerPoolId")}`, []),
            machinePoliciesPage: createPageRoute(routeTemplate `/machinepolicies`, []),
            editMachinePolicyPage: createPageRoute(routeTemplate `/machinepolicies/${routeParam("machinePolicyId")}`, []),
            createMachinePolicyPage: createPageRoute(routeTemplate `/machinepolicies/create`, []),
            proxiesPage: createPageRoute(routeTemplate `/proxies`, []),
            editProxyPage: createPageRoute(routeTemplate `/proxies/${routeParam("proxyId")}`, []),
            createProxyPage: createPageRoute(routeTemplate `/proxies/create`, []),
            infrastructureAccountsPage: createPageRoute(routeTemplate `/accounts`, []),
            editInfrastructureAccountPage: createPageRoute(routeTemplate `/accounts/${routeParam("accountId")}`, []),
            createInfrastructureAccountPage: createPageRoute(routeTemplate `/accounts/create`, [optionalStringQueryParam("accountType")]),
            dynamicEnvironmentsPage: createPageRoute(routeTemplate `/dynamicenvironments`, [optionalStringQueryParam("environmentDeleted")]),
            dynamicEnvironmentPage: createPageRoute(routeTemplate `/dynamicenvironments/${routeParam("environmentId")}/overview`, []),
        }),
        projects: createRouteSegment(routeTemplate `/projects`, {
            specificProject: createRouteSegment(routeTemplate `/${routeParam("projectSlug")}`, {
                branch: createRouteSegment(routeTemplate `/branches/${routeParam("branchName")}`, {
                    deployments: createRouteSegment(routeTemplate `/deployments`, {
                        branchDeploymentProcessCodePage: createPageRoute(routeTemplate `/process/edit`, []),
                        branchDeploymentProcessPage: createPageRoute(routeTemplate `/process`, [optionalBooleanQueryParam("newlyCreatedProject"), optionalBooleanQueryParam("configureProject"), optionalStringQueryParam("loadSampleProcess")]),
                        branchDeploymentProcessStepsPage: createPageRoute(routeTemplate `/process/steps`, [
                            optionalBooleanQueryParam("new"),
                            optionalBooleanQueryParam("stepTemplates"),
                            optionalBooleanQueryParam("childStepTemplates"),
                            optionalStringQueryParam("actionType"),
                            optionalStringQueryParam("templateId"),
                            optionalStringQueryParam("parentStepId"),
                            optionalStringQueryParam("actionId"),
                            optionalBooleanQueryParam("newlyCreatedProject"),
                            optionalStringQueryParam("actionCategory"),
                            optionalStringQueryParam("blueprintId"),
                            optionalStringQueryParam("blueprintActionId"),
                            optionalStringQueryParam("blueprintParentStepId"),
                            stringArrayQueryParam("tags"),
                        ]),
                        branchDeploymentProcessStepByStepSlugRedirect: createRedirect(routeTemplate `/process/steps/${routeParam("stepSlug")}`),
                        branchDeploymentProcessSettingsPage: createPageRoute(routeTemplate `/settings`, []),
                    }),
                    variables: createRouteSegment(routeTemplate `/variables`, {
                        branchVariablesPage: createPageRoute(routeTemplate ``, []),
                        branchAllVariablesPage: createPageRoute(routeTemplate `/all`, []),
                        branchVariablesPreviewPage: createPageRoute(routeTemplate `/preview`, []),
                    }),
                    branchProjectRunbooksPage: createPageRoute(routeTemplate `/operations/runbooks`, []),
                    specificRunbook: createRouteSegment(routeTemplate `/operations/runbooks/${routeParam("runbookId")}`, {
                        branchRunbookRootRedirect: createRedirect(routeTemplate ``),
                        branchProjectRunbookOverviewPage: createPageRoute(routeTemplate `/overview`, []),
                        branchProjectRunbookRunsListPage: createPageRoute(routeTemplate `/runslist`, []),
                        branchProjectRunbookSettingsPage: createPageRoute(routeTemplate `/settings`, []),
                        branchProjectRunbookSnapshotsPage: createPageRoute(routeTemplate `/snapshots`, []),
                        branchProcess: createRouteSegment(routeTemplate `/process`, {
                            branchProjectRunbookProcessListPage: createPageRoute(routeTemplate `/${routeParam("processId")}`, [optionalStringQueryParam("loadSampleProcess")]),
                            branchProjectRunbookProcessStepsPage: createPageRoute(routeTemplate `/${routeParam("processId")}/steps`, [
                                optionalBooleanQueryParam("new"),
                                optionalBooleanQueryParam("stepTemplates"),
                                optionalBooleanQueryParam("childStepTemplates"),
                                optionalStringQueryParam("actionType"),
                                optionalStringQueryParam("templateId"),
                                optionalStringQueryParam("parentStepId"),
                                optionalStringQueryParam("actionId"),
                                optionalStringQueryParam("blueprintId"),
                                optionalStringQueryParam("blueprintActionId"),
                                optionalStringQueryParam("blueprintParentStepId"),
                            ]),
                        }),
                        branchRunbookRunNowPage: createPageRoute(routeTemplate `/runNow`, []),
                        branchProjectRunbookRunDetailPage: createPageRoute(routeTemplate `/snapshots/${routeParam("runbookSnapshotId")}/runs/${routeParam("runbookRunId")}`, []),
                    }),
                }),
                withoutBranch: createRouteSegment(routeTemplate ``, {
                    projectRootRedirect: createRedirect(routeTemplate ``),
                    projectOverviewRedirect: createRedirect(routeTemplate `/overview`),
                    deployments: createRouteSegment(routeTemplate `/deployments`, {
                        process: createRouteSegment(routeTemplate `/process`, {
                            deploymentProcessPage: createPageRoute(routeTemplate ``, [optionalBooleanQueryParam("newlyCreatedProject"), optionalBooleanQueryParam("configureProject"), optionalStringQueryParam("loadSampleProcess")]),
                            deploymentProcessStepsPage: createPageRoute(routeTemplate `/steps`, [
                                optionalBooleanQueryParam("new"),
                                optionalBooleanQueryParam("stepTemplates"),
                                optionalBooleanQueryParam("childStepTemplates"),
                                optionalStringQueryParam("actionType"),
                                optionalStringQueryParam("templateId"),
                                optionalStringQueryParam("parentStepId"),
                                optionalStringQueryParam("actionId"),
                                optionalBooleanQueryParam("newlyCreatedProject"),
                                optionalStringQueryParam("actionCategory"),
                                optionalStringQueryParam("blueprintId"),
                                optionalStringQueryParam("blueprintActionId"),
                                optionalStringQueryParam("blueprintParentStepId"),
                                stringArrayQueryParam("tags"),
                            ]),
                            deploymentProcessStepByStepSlugRedirect: createRedirect(routeTemplate `/steps/${routeParam("stepSlug")}`),
                        }),
                        settings: createRouteSegment(routeTemplate `/settings`, {
                            deploymentProcessSettingsPage: createPageRoute(routeTemplate ``, []),
                        }),
                        deploymentsPage: createPageRoute(routeTemplate ``, [optionalBooleanQueryParam("newlyCreatedProject"), optionalBooleanQueryParam("configureProject")]),
                        channelsPage: createPageRoute(routeTemplate `/channels`, []),
                        createChannelPage: createPageRoute(routeTemplate `/channels/create`, []),
                        editChannelPage: createPageRoute(routeTemplate `/channels/edit/${routeParam("channelId")}`, []),
                        releasesPage: createPageRoute(routeTemplate `/releases`, []),
                        createReleasePage: createPageRoute(routeTemplate `/releases/create`, [optionalStringQueryParam("channelId")]),
                        specificRelease: createRouteSegment(routeTemplate `/releases/${routeParam("releaseVersion")}`, {
                            deploymentDetailsPage: createPageRoute(routeTemplate `/deployments/${routeParam("deploymentId")}`, []),
                            editReleasePage: createPageRoute(routeTemplate `/edit`, []),
                            releasePage: createPageRoute(routeTemplate ``, []),
                            createDeploymentPage: createPageRoute(routeTemplate `/deployments/create`, [
                                stringArrayQueryParam("environmentIds"),
                                stringArrayQueryParam("tenantIds"),
                                stringArrayQueryParam("tenantTags"),
                                optionalStringQueryParam("previousDeploymentId"),
                            ]),
                        }),
                    }),
                    variables: createRouteSegment(routeTemplate `/variables`, {
                        // Here we create an additional route segment at the same location as the project variables page.
                        // This is because we want to wrap the project variables page with a branch redirect component.
                        // However we don't want this to apply to all sub routes of the variables partial route, (e.g. the variables library route is not version controlled so should not redirect).
                        // So this additional partial route layer allows us to isolate this redirect behaviour to just the routes that need it.
                        wrapper: createRouteSegment(routeTemplate ``, {
                            variablesPage: createPageRoute(routeTemplate ``, []),
                        }),
                        all: createRouteSegment(routeTemplate `/all`, {
                            allVariablesPage: createPageRoute(routeTemplate ``, []),
                        }),
                        preview: createRouteSegment(routeTemplate `/preview`, {
                            variablesPreviewPage: createPageRoute(routeTemplate ``, []),
                        }),
                        tenant: createRouteSegment(routeTemplate `/tenant`, {
                            projectTenantsRedirect: createRedirect(routeTemplate ``),
                            projectTenantProjectTemplatesPage: createPageRoute(routeTemplate `/project-templates`, []),
                            projectTenantCommonTemplatesPage: createPageRoute(routeTemplate `/common-templates`, []),
                        }),
                        libraryVariablesPage: createPageRoute(routeTemplate `/library`, []),
                        variableTemplatesPage: createPageRoute(routeTemplate `/projectvariabletemplates`, []),
                        commonVariableTemplatesPage: createPageRoute(routeTemplate `/commonvariabletemplates`, []),
                    }),
                    projectInsightsOverviewPage: createPageRoute(routeTemplate `/insights`, [projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, insightsCadenceParam, projectInsightsTenantIdParam]),
                    projectInsightsLeadTimePage: createPageRoute(routeTemplate `/insights/lead-time`, [projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, insightsCadenceParam, projectInsightsTenantIdParam]),
                    projectInsightsFrequencyPage: createPageRoute(routeTemplate `/insights/frequency`, [projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, insightsCadenceParam, projectInsightsTenantIdParam]),
                    projectInsightsFailureRatePage: createPageRoute(routeTemplate `/insights/failure-rate`, [projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, insightsCadenceParam, projectInsightsTenantIdParam]),
                    projectInsightsTimeToRecoveryPage: createPageRoute(routeTemplate `/insights/mttr`, [projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, insightsCadenceParam, projectInsightsTenantIdParam]),
                    projectInsightsDeploymentDurationPage: createPageRoute(routeTemplate `/insights/deployment-duration`, [projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, insightsCadenceParam, projectInsightsTenantIdParam]),
                    projectOperationsPage: createPageRoute(routeTemplate `/operations`, []),
                    projectRunbookTriggersPage: createPageRoute(routeTemplate `/operations/triggers`, [
                        optionalStringQueryParam("searchText"),
                        optionalBooleanQueryParam("showDeploymentTargetTriggers"),
                        optionalBooleanQueryParam("showScheduledTriggers"),
                        optionalStringQueryParam("runbookId"),
                    ]),
                    projectRunbookCreateScheduledTriggerPage: createPageRoute(routeTemplate `/operations/triggers/scheduled/create/runbooks`, []),
                    projectRunbookCreateScheduledTriggerForRunbookPage: createPageRoute(routeTemplate `/operations/triggers/scheduled/create/runbooks/${routeParam("runbookId")}`, []),
                    projectRunbookEditScheduledTriggerPage: createPageRoute(routeTemplate `/operations/triggers/scheduled/edit/${routeParam("triggerId")}`, []),
                    projectRunbooks: createRouteSegment(routeTemplate `/operations/runbooks`, {
                        projectRunbooksPage: createPageRoute(routeTemplate ``, []),
                    }),
                    specificRunbook: createRouteSegment(routeTemplate `/operations/runbooks/${routeParam("runbookId")}`, {
                        runbookRootRedirect: createRedirect(routeTemplate ``),
                        projectRunbookOverviewPage: createPageRoute(routeTemplate `/overview`, []),
                        projectRunbookRunsListPage: createPageRoute(routeTemplate `/runslist`, []),
                        projectRunbookSettingsPage: createPageRoute(routeTemplate `/settings`, []),
                        projectRunbookSnapshotsPage: createPageRoute(routeTemplate `/snapshots`, []),
                        process: createRouteSegment(routeTemplate `/process`, {
                            projectRunbookProcessListPage: createPageRoute(routeTemplate `/${routeParam("processId")}`, [optionalStringQueryParam("loadSampleProcess")]),
                            projectRunbookProcessStepsPage: createPageRoute(routeTemplate `/${routeParam("processId")}/steps`, [
                                optionalBooleanQueryParam("new"),
                                optionalBooleanQueryParam("stepTemplates"),
                                optionalBooleanQueryParam("childStepTemplates"),
                                optionalStringQueryParam("actionType"),
                                optionalStringQueryParam("templateId"),
                                optionalStringQueryParam("parentStepId"),
                                optionalStringQueryParam("actionId"),
                                optionalStringQueryParam("blueprintId"),
                                optionalStringQueryParam("blueprintActionId"),
                                optionalStringQueryParam("blueprintParentStepId"),
                            ]),
                            projectRubookProcessStepByStepSlugRedirect: createRedirect(routeTemplate `/${routeParam("processId")}/steps/${routeParam("stepSlug")}`),
                        }),
                        createRunbookRunForSnapshotPage: createPageRoute(routeTemplate `/snapshots/${routeParam("runbookSnapshotId")}/runs/create`, [stringArrayQueryParam("environmentIds"), optionalStringQueryParam("previousRunbookRunId")]),
                        runbookRunNowPage: createPageRoute(routeTemplate `/runNow`, []),
                        runbookRunSnapshotNowPage: createPageRoute(routeTemplate `/runNow/${routeParam("runbookSnapshotId")}`, []),
                        projectRunbookSnapshotCreatePage: createPageRoute(routeTemplate `/snapshots/create`, []),
                        projectRunbookSnapshotEditPage: createPageRoute(routeTemplate `/snapshots/${routeParam("runbookSnapshotId")}/edit`, []),
                        projectRunbookSnapshotInfoPage: createPageRoute(routeTemplate `/snapshots/${routeParam("runbookSnapshotId")}`, []),
                        projectRunbookRunDetailPage: createPageRoute(routeTemplate `/snapshots/${routeParam("runbookSnapshotId")}/runs/${routeParam("runbookRunId")}`, []),
                    }),
                    projectTasksPage: createPageRoute(routeTemplate `/tasks`, [
                        stringArrayQueryParam("ids"),
                        taskFilterStateParam,
                        optionalStringQueryParam("runbook"),
                        optionalStringQueryParam("environment"),
                        optionalStringQueryParam("name"),
                        optionalStringQueryParam("serverNode"),
                        optionalStringQueryParam("tenant"),
                        stringArrayQueryParam("spaces"),
                        optionalBooleanQueryParam("hasPendingInterruptions"),
                        optionalBooleanQueryParam("hasWarningsOrErrors"),
                        taskDateFilterTypeParam,
                        dateQueryParam("fromDate"),
                        dateQueryParam("toDate"),
                        taskListTabParam,
                    ]),
                    projectTaskDetailsPage: createPageRoute(routeTemplate `/tasks/${routeParam("taskId")}`, []),
                    projectTenantsPage: createPageRoute(routeTemplate `/tenants`, []),
                    deploymentTriggersPage: createPageRoute(routeTemplate `/triggers`, []),
                    createDeploymentTriggerPage: createPageRoute(routeTemplate `/triggers/create`, []),
                    createReleaseCreationFeedTriggerPage: createPageRoute(routeTemplate `/triggers/feed/create`, []),
                    editReleaseCreationFeedTriggerPage: createPageRoute(routeTemplate `/triggers/feed/edit/${routeParam("triggerId")}`, []),
                    createBuiltInFeedTriggerPage: createPageRoute(routeTemplate `/triggers/built-in-feed/create`, []),
                    editBuiltInFeedTriggerPage: createPageRoute(routeTemplate `/triggers/built-in-feed/edit`, []),
                    editDeploymentTriggerPage: createPageRoute(routeTemplate `/triggers/edit/${routeParam("triggerId")}`, []),
                    createDeploymentScheduledTriggerPage: createPageRoute(routeTemplate `/triggers/scheduled/create`, []),
                    editDeploymentScheduledTriggerPage: createPageRoute(routeTemplate `/triggers/scheduled/edit/${routeParam("triggerId")}`, []),
                    projectSettingsPage: createPageRoute(routeTemplate `/settings`, []),
                    projectVersionControlSettingsPage: createPageRoute(routeTemplate `/settings/versioncontrol`, [optionalBooleanQueryParam("newlyCreatedProject"), optionalBooleanQueryParam("configureProject")]),
                    projectITSMProvidersControlSettingsPage: createPageRoute(routeTemplate `/settings/itsmproviders`, []),
                    projectDynamicEnvironmentSettingsPage: createPageRoute(routeTemplate `/settings/dynamicenvironments`, []),
                    featureTogglesPage: createPageRoute(routeTemplate `/featuretoggles`, []),
                    featureTogglePage: createPageRoute(routeTemplate `/featuretoggles/${routeParam("featureToggleSlug")}`, []),
                }),
            }),
            projectsPage: createPageRoute(routeTemplate ``, [
                // legacy navigation
                optionalStringQueryParam("projectGroupId"),
                optionalStringQueryParam("projectName"),
                optionalStringQueryParam("newProjectGroupId"),
                // vertical navigation
                stringQueryParam("searchValue"),
                numberQueryParam("page"),
                numberQueryParam("pageSize"),
            ]),
            importExports: createRouteSegment(routeTemplate `/importExport`, {
                importExportProjectTasksPage: createPageRoute(routeTemplate `/overview`, []),
                importProjectsPage: createPageRoute(routeTemplate `/import`, []),
                importProjectTaskPage: createPageRoute(routeTemplate `/import/${routeParam("taskId")}`, []),
                exportProjectsPage: createPageRoute(routeTemplate `/export`, []),
                exportProjectTaskPage: createPageRoute(routeTemplate `/export/${routeParam("taskId")}`, []),
            }),
        }),
        deploymentToProjectTaskRedirect: createRedirect(routeTemplate `/deployments/${routeParam("deploymentId")}`),
        tenants: createRouteSegment(routeTemplate `/tenants`, {
            tenantsPage: createPageRoute(routeTemplate ``, [
                stringQueryParam("search"),
                stringQueryParam("excludedSearch"),
                stringQueryParam("project"),
                stringQueryParam("excludedProject"),
                stringQueryParam("environment"),
                stringQueryParam("excludedEnvironment"),
                stringQueryParam("tags"), // comma separate values. TODO: Migrate this to an array type
                stringQueryParam("excludedTags"), // comma separate values. TODO: Migrate this to an array type
                numberQueryParam("pageNumber"),
                booleanQueryParam("includeMissingVariablesStatus"),
            ]),
            specificTenant: createRouteSegment(routeTemplate `/${routeParam("tenantId")}`, {
                tenantOverviewPage: createPageRoute(routeTemplate `/overview`, []),
                tenantVariablesPage: createPageRoute(routeTemplate `/variables`, [createTenantActiveTabQueryParam("activeTab")]),
                tenantSettingsPage: createPageRoute(routeTemplate `/settings`, []),
                tenantTasksPage: createPageRoute(routeTemplate `/tasks`, []),
            }),
        }),
        library: createRouteSegment(routeTemplate `/library`, {
            libraryRootRedirect: createRedirect(routeTemplate ``),
            builtInRepositoryPage: createPageRoute(routeTemplate `/builtinrepository`, []),
            packageDetailsPage: createPageRoute(routeTemplate `/builtinrepository/${routeParam("id")}`, []),
            packageVersionsListPage: createPageRoute(routeTemplate `/builtinrepository/versions/${routeParam("packageId")}`, []),
            buildInformationOverviewPage: createPageRoute(routeTemplate `/buildinformation`, []),
            buildInformationVersionsPage: createPageRoute(routeTemplate `/buildinformation/versions/${routeParam("packageId")}`, []),
            buildInformationDetailPage: createPageRoute(routeTemplate `/buildinformation/${routeParam("id")}`, []),
            feedsPage: createPageRoute(routeTemplate `/feeds`, []),
            createFeedPage: createPageRoute(routeTemplate `/feeds/create`, []),
            editFeedPage: createPageRoute(routeTemplate `/feeds/${routeParam("feedId")}`, []),
            testFeedPage: createPageRoute(routeTemplate `/feeds/${routeParam("feedId")}/test`, []),
            redirectFeedBasedOnType: createRedirect(routeTemplate `/feeds/${routeParam("feedId")}/redirect`),
            lifecyclesPage: createPageRoute(routeTemplate `/lifecycles`, []),
            createLifecyclePage: createPageRoute(routeTemplate `/lifecycles/create`, []),
            editLifecyclePage: createPageRoute(routeTemplate `/lifecycles/${routeParam("lifecycleId")}`, []),
            scriptModulesPage: createPageRoute(routeTemplate `/scripts`, []),
            createScriptModulePage: createPageRoute(routeTemplate `/scripts/create`, []),
            editScriptModulePage: createPageRoute(routeTemplate `/scripts/${routeParam("scriptModuleId")}`, []),
            stepTemplatesPage: createPageRoute(routeTemplate `/steptemplates`, []),
            builtInStepTemplatesPage: createPageRoute(routeTemplate `/steptemplates/builtin`, []),
            communityStepTemplatesPage: createPageRoute(routeTemplate `/steptemplates/community`, []),
            communityStepTemplatePage: createPageRoute(routeTemplate `/steptemplates/community/${routeParam("templateId")}`, [optionalStringQueryParam("projectId")]),
            createStepTemplatePage: createPageRoute(routeTemplate `/steptemplates/new/${routeParam("actionType")}`, []),
            editStepTemplatePage: createPageRoute(routeTemplate `/steptemplates/${routeParam("templateId")}`, []),
            runStepTemplatePage: createPageRoute(routeTemplate `/steptemplates/${routeParam("templateId")}/run`, [optionalStringQueryParam("retry")]),
            stepTemplateUsagePage: createPageRoute(routeTemplate `/steptemplates/${routeParam("templateId")}/usage`, []),
            tagSetsPage: createPageRoute(routeTemplate `/tagsets`, []),
            createTagSetPage: createPageRoute(routeTemplate `/tagsets/create`, []),
            editTagSetPage: createPageRoute(routeTemplate `/tagsets/${routeParam("tagSetId")}`, []),
            variableSetsPage: createPageRoute(routeTemplate `/variables`, []),
            editVariableSetPage: createPageRoute(routeTemplate `/variables/${routeParam("variableSetId")}`, [variableSetTabParam]),
            gitCredentialsPage: createPageRoute(routeTemplate `/gitcredentials`, []),
            createGitCredentialPage: createPageRoute(routeTemplate `/gitcredentials/create`, []),
            editGitCredentialPage: createPageRoute(routeTemplate `/gitcredentials/${routeParam("gitCredentialId")}`, []),
            gitConnectionsPage: createPageRoute(routeTemplate `/gitconnections`, []),
            createGitHubConnectionPage: createPageRoute(routeTemplate `/gitconnections/add/github/${routeParam("installationId")}`, []),
            editGitHubConnectionPage: createPageRoute(routeTemplate `/gitconnections/github/${routeParam("connectionId")}`, []),
            archiveCertificatePage: createPageRoute(routeTemplate `/certificates/archive`, []),
            createCertificatePage: createPageRoute(routeTemplate `/certificates/create`, []),
            editCertificatePage: createPageRoute(routeTemplate `/certificates/${routeParam("certificateId")}`, []),
            certificatesPage: createPageRoute(routeTemplate `/certificates`, []),
        }),
        insights: createRouteSegment(routeTemplate `/insights`, {
            insightsRootRedirect: createRedirect(routeTemplate ``),
            insightsUpsellPage: createPageRoute(routeTemplate `/unlicensed`, []),
            reports: createRouteSegment(routeTemplate `/reports`, {
                insightReportListPage: createPageRoute(routeTemplate ``, []),
                specificReport: createRouteSegment(routeTemplate `/${routeParam("reportId")}`, {
                    insightsReportOverviewPage: createPageRoute(routeTemplate `/overview`, [insightsSplitParam, insightsCadenceParam, insightsTenantTagSetIdParam]),
                    insightsReportDeploymentFrequencyPage: createPageRoute(routeTemplate `/frequency`, [insightsSplitParam, insightsCadenceParam, insightsTenantTagSetIdParam]),
                    insightsReportDeploymentLeadTimePage: createPageRoute(routeTemplate `/lead-time`, [insightsSplitParam, insightsCadenceParam, insightsTenantTagSetIdParam]),
                    insightsReportDeploymentFailureRatePage: createPageRoute(routeTemplate `/failure-rate`, [insightsSplitParam, insightsCadenceParam, insightsTenantTagSetIdParam]),
                    insightsReportDeploymentMeanTimeToRecoveryPage: createPageRoute(routeTemplate `/mttr`, [insightsSplitParam, insightsCadenceParam, insightsTenantTagSetIdParam]),
                    insightsReportDeploymentDurationPage: createPageRoute(routeTemplate `/deployment-duration`, [insightsSplitParam, insightsCadenceParam, insightsTenantTagSetIdParam]),
                    insightsReportSettingsPage: createPageRoute(routeTemplate `/settings`, []),
                }),
            }),
        }),
        blueprints: createRouteSegment(routeTemplate `/blueprints`, {
            blueprintsPage: createPageRoute(routeTemplate ``, []),
            blueprintsRepositoriesPage: createPageRoute(routeTemplate `/repositories`, []),
            connectBlueprintsRepositoryPage: createPageRoute(routeTemplate `/repositories/connect`, []),
            editBlueprintsRepositoryPage: createPageRoute(routeTemplate `/repositories/${routeParam("projectId")}`, []),
            editBlueprintPage: createPageRoute(routeTemplate `/${routeParam("blueprintId")}`, [
                optionalBooleanQueryParam("stepTemplates"),
                optionalStringQueryParam("actionId"),
                optionalBooleanQueryParam("new"),
                optionalBooleanQueryParam("childStepTemplates"),
                optionalStringQueryParam("actionType"),
                optionalStringQueryParam("parentStepId"),
                optionalStringQueryParam("templateId"),
            ]),
        }),
    }),
});
export enum TenantVariableActiveTab {
    CommonVariables = "commonVariables",
    ProjectVariables = "projectVariables"
}
export function exhaustiveFallback<T>(param: never, fallback: T): T {
    return fallback;
}
function isTenantVariableTab(value: string): value is TenantVariableActiveTab {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const converted = value as TenantVariableActiveTab;
    switch (converted) {
        case TenantVariableActiveTab.CommonVariables:
        case TenantVariableActiveTab.ProjectVariables:
            return true;
        default:
            return exhaustiveFallback(converted, false);
    }
}
function createTenantActiveTabQueryParam<TKey extends string>(name: TKey) {
    return createQueryParam<TKey, TenantVariableActiveTab | undefined>(name, (serializedValue) => {
        if (serializedValue === undefined)
            return undefined;
        return isTenantVariableTab(serializedValue) ? serializedValue : undefined;
    }, (value) => value);
}
function validateQuerySplit(serializedValue: string | undefined): InsightsMetricsSplit | undefined {
    if (serializedValue === undefined)
        return undefined;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const possibleSplit = serializedValue as InsightsMetricsSplit;
    return Object.values(InsightsMetricsSplitValues).includes(possibleSplit) ? possibleSplit : undefined;
}
function validateQueryCadence(queryCadence: string | undefined): InsightsCadence | undefined {
    if (queryCadence === undefined)
        return undefined;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const possibleCadence = queryCadence as InsightsCadence;
    return Object.values(InsightsCadenceValues).includes(possibleCadence) ? possibleCadence : undefined;
}
export const InsightsMetricsSplitValues = {
    None: "None",
    Project: "Project",
    ProjectGroup: "ProjectGroup",
    Environment: "Environment",
    EnvironmentGroup: "EnvironmentGroup",
    Tenant: "Tenant",
    TenantTagSet: "TenantTagSet",
} as const;
export type InsightsMetricsSplit = (typeof InsightsMetricsSplitValues)[keyof typeof InsightsMetricsSplitValues];
export const InsightsCadenceValues = {
    LastYearMonthly: "lastYearMonthly",
    LastYearWeekly: "lastYearWeekly",
    LastQuarterWeekly: "lastQuarterWeekly",
    LastQuarterDaily: "lastQuarterDaily",
    LastMonthDaily: "lastMonthDaily",
} as const;
export type InsightsCadence = (typeof InsightsCadenceValues)[keyof typeof InsightsCadenceValues];
export const EndpointRegistrationKeyValues = {
    CloudRegion: "CloudRegion",
    TentaclePassive: "TentaclePassive",
    TentacleActive: "TentacleActive",
    Ssh: "Ssh",
    OfflineDrop: "OfflineDrop",
    AzureWebApp: "AzureWebApp",
    AzureCloudService: "AzureCloudService",
    AzureServiceFabricCluster: "AzureServiceFabricCluster",
    AzureVmExtension: "AzureVmExtension",
    Kubernetes: "Kubernetes",
    StepPackage: "StepPackage",
    KubernetesTentacle: "KubernetesTentacle",
};
export type EndpointRegistrationKey = (typeof EndpointRegistrationKeyValues)[keyof typeof EndpointRegistrationKeyValues];
export const VariableSetTabValues = {
    Variables: "variables",
    VariableTemplates: "variableTemplates",
} as const;
export type VariableSetTab = (typeof VariableSetTabValues)[keyof typeof VariableSetTabValues];
function validateVariableSetTab(variableSetTab: string | undefined): VariableSetTab | undefined {
    if (variableSetTab === undefined)
        return undefined;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const possibleTab = variableSetTab as VariableSetTab;
    return Object.values(VariableSetTabValues).includes(possibleTab) ? possibleTab : undefined;
}
export type ProjectInsightsFilterQueryParams = {
    channelId?: string;
    environmentId?: string;
    tenantId?: string;
    cadence?: InsightsCadence;
};
export const TaskFilterStateValues = {
    Incomplete: "Incomplete",
    Running: "Running",
    Completed: "Completed",
    Unsuccessful: "Unsuccessful",
    Queued: "Queued",
    Executing: "Executing",
    Cancelling: "Cancelling",
    Success: "Success",
    Canceled: "Canceled",
    TimedOut: "TimedOut",
    Failed: "Failed",
} as const;
export type TaskFilterState = (typeof TaskFilterStateValues)[keyof typeof TaskFilterStateValues];
export const TaskListTabValues = {
    All: "All",
    Running: "Running",
    Waiting: "Waiting",
    Completed: "Completed",
} as const;
export type TaskListTab = (typeof TaskListTabValues)[keyof typeof TaskListTabValues];
export const TaskDateFilterTypeValues = {
    QueueTime: "Queue Time",
    StartTime: "Start Time",
    CompletedTime: "Completed Time",
} as const;
export type TaskDateFilterType = (typeof TaskDateFilterTypeValues)[keyof typeof TaskDateFilterTypeValues];
function validateTaskFilterState(serializedValue: string | undefined): TaskFilterState | undefined {
    if (serializedValue === undefined)
        return undefined;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const possibleSplit = serializedValue as TaskFilterState;
    return Object.values(TaskFilterStateValues).includes(possibleSplit) ? possibleSplit : undefined;
}
function validateTaskListTab(serializedValue: string | undefined): TaskListTab | undefined {
    if (serializedValue === undefined)
        return undefined;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const possibleSplit = serializedValue as TaskListTab;
    return Object.values(TaskListTabValues).includes(possibleSplit) ? possibleSplit : undefined;
}
function validateTaskDateFilterType(serializedValue: string | undefined): TaskDateFilterType | undefined {
    if (serializedValue === undefined)
        return undefined;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const possibleSplit = serializedValue as TaskDateFilterType;
    return Object.values(TaskDateFilterTypeValues).includes(possibleSplit) ? possibleSplit : undefined;
}
export const MachineHealthStatusValues = {
    Healthy: "Healthy",
    Unavailable: "Unavailable",
    Unknown: "Unknown",
    HasWarnings: "HasWarnings",
    Unhealthy: "Unhealthy",
} as const;
export type MachineHealthStatus = (typeof MachineHealthStatusValues)[keyof typeof MachineHealthStatusValues];
export const CommunicationStyleValues = {
    None: "None",
    TentaclePassive: "TentaclePassive",
    TentacleActive: "TentacleActive",
    Ssh: "Ssh",
    OfflineDrop: "OfflineDrop",
    AzureWebApp: "AzureWebApp",
    AzureCloudService: "AzureCloudService",
    AzureServiceFabricCluster: "AzureServiceFabricCluster",
    Kubernetes: "Kubernetes",
    KubernetesTentacle: "KubernetesTentacle",
    StepPackage: "StepPackage",
} as const;
export type CommunicationStyle = (typeof CommunicationStyleValues)[keyof typeof CommunicationStyleValues];
function validateMachineHealthStatus(serializedValues: string[]): MachineHealthStatus[] {
    return serializedValues
        .map((value) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const possibleSplit = value as MachineHealthStatus;
        return Object.values(MachineHealthStatusValues).includes(possibleSplit) ? possibleSplit : null;
    })
        .filter((v): v is MachineHealthStatus => v !== null);
}
function validateCommunicationStyle(serializedValues: string[]): CommunicationStyle[] {
    return serializedValues
        .map((value) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const possibleSplit = value as CommunicationStyle;
        return Object.values(CommunicationStyleValues).includes(possibleSplit) ? possibleSplit : null;
    })
        .filter((v): v is CommunicationStyle => v !== null);
}
